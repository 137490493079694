// import gps from '../store/gps';
import state from '../store/index'
import * as signalR from "@microsoft/signalr"

// const BUSHUB_URL = 'http://localhost:7001/signalR/busHub'
// const BUSHUB_URL = 'http://bus.chxinsd.com/signalR/busHub'
const BUSHUB_URL = '/signalr/busHub'

let __busHub = null

function connectBusHub() {
  if (__busHub != null) {
    return Promise.resolve(__busHub)
  } else {
    const connection = new signalR.HubConnectionBuilder().withAutomaticReconnect().withUrl(BUSHUB_URL).build()
    return new Promise(function (resolve, reject) {
      connection.start().then(() => {
        console.log('SignalR连接已建立')
        __busHub = connection
        __busHub.onreconnected(() => {
          reconnected()
        })
        receiveUpdate(connection)
        resolve(__busHub)
      }).catch(err => {
        reject(err)
      })
    })
  }
}

function reconnected() {
  console.log('signalR reconnected')
  try {
    const { busLineId, direction, busStationId } = state.getters.getWaitingOptions()
    waitBus(__busHub, busLineId, direction, busStationId)
      .then(() => { console.log('重连订阅线路')})
      .catch(err => { console.log('重连订阅失败' + err) })
  } catch (ex) {
    console.log(ex)
  }
}

function receiveUpdate (connection) {
  connection.on("update", waitingInfo => {
    // console.log('update invoked with data')
    // console.log(waitingInfo)
    state.commit('setWaitingInfo', waitingInfo)
  })
}

function getWaitingBusStations (connection, latitude, longitude) {
  return  (connection || __busHub).invoke("GetWaitingBusStations", latitude, longitude )
}

function getBusLineDeparture (connection, busLineId, direction) {
  return  (connection || __busHub).invoke("GetBusLineDeparture", busLineId, direction)
}

function waitBus (connection, busLineId, direction, busStationId) {
  return  (connection || __busHub).invoke("WaitBus", busLineId, direction, busStationId)
}

function getBusLine (connection, busLineId, direction) {
  return  (connection || __busHub).invoke("GetBusLine", busLineId, direction)
}

function setLocation (connection, latitude, longitude) {
  return  (connection || __busHub).invoke("SetLocation", latitude, longitude)
}

function getBusLinesByStationId (connection, busStationId) {
  return  (connection || __busHub).invoke("GetBusLinesByStationId", busStationId)
}

export {
  connectBusHub,
  getWaitingBusStations,
  getBusLineDeparture,
  getBusLine,
  getBusLinesByStationId,
  setLocation,
  waitBus
}
